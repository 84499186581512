<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bsnDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="dateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.salesSumDiv }}
            </div>
            <ul class="content">
              <div class="item" style="width: 80px">
                <ejs-dropdownlist
                    v-model="searchConditions.salesSumDiv"
                    :dataSource="searchOptions.salesSumDiv"
                    :fields="commonCodeFields"
                    :allowFiltering="false"
                    cssClass="lookup-condition-dropdown"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="aditFlag"
                          @change="fetch"
                      />
                      <i></i>
                      <div class="label">{{ labels.aditFlag }}</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="holeTeamApplyCheck"
                          @change="fetch"
                      />
                      <i></i>
                      <div class="label">{{ labels.holeTeamApply }}</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field" v-if="isDailyTab">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          v-model="weekRadio"
                          value=""
                          name="bsnCode"
                      />
                      <i></i>
                      <div class="label">{{ labels.bsnAll }}</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          v-model="weekRadio"
                          value="WEEKDAY"
                          name="bsnCode"
                      />
                      <i></i>
                      <div class="label">{{ labels.weekDay }}</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          v-model="weekRadio"
                          value="WEEKEND"
                          name="bsnCode"
                      />
                      <i></i>
                      <div class="label">{{ labels.weekEnd }}</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="onViewSalesStatusByPart"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">부별 매출 현황</div>
              <div class="header-caption">[{{ salesStatusByPartList.length | numberWithCommas }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                  ref="courseDivTab"
                  :items="tabList"
                  :showCloseButton="false"
                  heightAdjustMode="Auto"
                  overflowMode="Popup"
                  @selected="selectTab"
              />
            </div>
          </div>
          <div
              v-if="salesStatusByPartList.length > 0"
              class="section-body"
          >
            <div
                v-if="selectedTab === 'NORMAL'"
                class="body-grid"
            >
              <ejs-grid-wrapper
                  ref="salesStatusByPartGrid"
                  :allowExcelExport="true"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :isNOColumnDisplay="false"
                  :provides="grid"
                  :groupSettings="groupSettings"
                  :columns="salesStatusByPartGridColumn"
                  :dataSource="salesStatusByPartList"
                  :aggregates="salesStatusByPartGridAggregates"
                  @queryCellInfo="salesStatusByPartGridQueryCellInfo"
              />
            </div>
            <div
                v-if="isDailyTab"
                class="body-grid"
            >
              <ejs-grid-wrapper
                  ref="dailySalesStatusByPartGrid"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :allowResizing="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :columns="dailySalesStatusByPartGridColumn"
                  :dataSource="salesStatusByPartList"
                  :aggregates="dailySalesStatusByPartGridAggregates"
                  @headerCellInfo="dailySalesStatusByPartGridHeaderCellInfo"
                  @queryCellInfo="dailySalesStatusByPartGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import moment from 'moment';
import {
  DATE_FORMAT_YYYY_MM_DD,
  DATE_FORMAT_M,
  getDayColorValue,
} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import ErpButton from "@/components/button/ErpButton.vue";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
} from '@/utils/commonCodes';
import GolfERPService from '@/service/GolfERPService';

export default {
  name: 'SalesStatusByPart',
  components: {
    ejsGridWrapper,
    InputDateRange,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  data() {
    return {
      labels: {
        bsnDate: '영업일자',
        salesSumDiv: '구분',
        aditFlag: '추가팀 포함',
        holeTeamApply: '9홀 이하 0.5팀 적용',
        bsnAll: '전체',
        weekDay: '주중',
        weekEnd: '주말'
      },
      weekRadio: "",
      aditFlag :true,
      holeTeamApplyCheck: false,
      searchConditions: {
        salesSumDiv: commonCodeGetComCodeDefaultValue('SALES_SUM_DIV'),
      },
      searchOptions: {
        salesSumDiv: commonCodesGetCommonCode('SALES_SUM_DIV', true),
      },
      commonCodeFields: {text: 'comName', value: 'comCode'},
      partDivList: commonCodesGetCommonCode('PART_DIV', true),
      bsnCodeList: commonCodesGetCommonCode('BSN_CODE', true),
      now: null,
      dateRange: {
        from: null,
        to: null,
      },
      tabList: [
        {
          header: {text: '부별 매출 현황'},
          selectedTab: 'NORMAL',
        },
        {
          header: {text: '합계(일자별)'},
          selectedTab: 'DAILY_SUM',
        },
        {
          header: {text: '1부(일자별)'},
          selectedTab: 'DAILY_1',
        },
        {
          header: {text: '2부(일자별)'},
          selectedTab: 'DAILY_2',
        },
        {
          header: {text: '3부(일자별)'},
          selectedTab: 'DAILY_3',
        },
      ],
      selectedTab: 'NORMAL',
      grid: [
        Aggregate,
        Filter,
        Resize,
        Page,
        Group,
        ExcelExport,
        ForeignKey,
      ],
      salesStatusByPartGridColumn: [
        {
          field: 'partDivKey',
          type: 'string',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'group',
          type: 'string',
          visible: false,
        },
        {
          field: 'bsnCode',
          headerText: '구분',
          textAlign: 'center',
          width: 50,
          type: 'string',
          isCommonCodeField: true,
          groupCode: 'BSN_CODE',
        },
        {
          field: 'partDiv',
          headerText: '부',
          textAlign: 'center',
          width: 50,
          type: 'string',
          isCommonCodeField: true,
          groupCode: 'PART_DIV',
        },
        {
          headerText: '팀정보',
          columns: [
            {
              field: 'resveAbleTeam',
              headerText: '가용팀수',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'visitTeam',
              headerText: '운영팀수',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'visitPer',
              headerText: '내장객수',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
        {
          headerText: '지역민',
          columns: [
            {
              field: 'nearbyPer',
              headerText: '내장객',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'nearbyPerRatio',
              headerText: '비율(%)',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
        {
          headerText: '키오스크',
          columns: [
            {
              field: 'autoChkinPer',
              headerText: '내장객',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'autoChkinPerRatio',
              headerText: '비율(%)',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
        {
          headerText: '남자',
          columns: [
            {
              field: 'malePer',
              headerText: '내장객',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'malePerRatio',
              headerText: '비율(%)',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
        {
          headerText: '여자',
          columns: [
            {
              field: 'femalePer',
              headerText: '내장객',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'femalePerRatio',
              headerText: '비율(%)',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
        {
          field: 'golfUnitPrice',
          headerText: '골프객단가',
          textAlign: 'right',
          isNumericType: true,
          width: 120,
          type: 'number',
        },
        {
          headerText: '매출정보',
          columns: [
            {
              field: 'totSalesAmt',
              headerText: '합계',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'feeAmt',
              headerText: '입장료',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'cartAmt',
              headerText: '카트료',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'rentAmt',
              headerText: '대여료',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'foodAmt',
              headerText: '식음료',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'shopAmt',
              headerText: '프로샵',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'roomAmt',
              headerText: '객실매출',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
      ],
      dailySalesStatusByPartGridColumn: [
        {
          field: 'bsnDateKey',
          type: 'string',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'bsnDate',
          headerText: '영업일자',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'dwCode',
          headerText: '요일',
          textAlign: 'center',
          width: 65,
          type: 'string',
          isCommonCodeField: true,
          groupCode: 'DW_CODE',
        },
        {
          field: 'bsnCode',
          headerText: '영업구분',
          textAlign: 'center',
          width: 90,
          type: 'string',
          isCommonCodeField: true,
          groupCode: 'BSN_CODE',
        },
      ],
      salesStatusByPartGridAggregates: [
        {
          columns: [
            {
              field: 'partDiv',
              aggregationType: 'GroupCaption',
              customAggregate: '소계',
            },
            {
              field: 'resveAbleTeam',
              aggregationType: 'GroupSum',
            },
            {
              field: 'resveAbleTeam',
              aggregationType: 'TotalSum',
            },
            {
              field: 'partDiv',
              aggregationType: 'TotalCaption',
              customAggregate: '합계',
            },
            {
              field: 'nearbyPer',
              aggregationType: 'TotalSum',
            },
            {
              field: 'nearbyPerRatio',
              aggregationType: 'TotalCaption',
              customAggregate: () => {
                const gridData = this.$refs.salesStatusByPartGrid.getGridBatchData();
                const nearbyPer = gridData
                  .map(item => item.nearbyPer)
                  .reduce((acc, cur) => cur + acc, 0);
                const visitPer = gridData
                  .map(item => item.visitPer)
                  .reduce((acc, cur) => cur + acc, 0);
                return visitPer > 0
                  ? ((nearbyPer / visitPer) * 100).toFixed(1)
                  : '-';
              },
            },
            {
              field: 'autoChkinPer',
              aggregationType: 'TotalSum',
            },
            {
              field: 'autoChkinPerRatio',
              aggregationType: 'TotalCaption',
              customAggregate: () => {
                const gridData = this.$refs.salesStatusByPartGrid.getGridBatchData();
                const autoChkinPer = gridData
                  .map(item => item.autoChkinPer)
                  .reduce((acc, cur) => cur + acc, 0);
                const visitPer = gridData
                  .map(item => item.visitPer)
                  .reduce((acc, cur) => cur + acc, 0);
                return visitPer > 0
                  ? ((autoChkinPer / visitPer) * 100).toFixed(1)
                  : '-';
              },
            },
            {
              field: 'malePer',
              aggregationType: 'TotalSum',
            },
            {
              field: 'malePerRatio',
              aggregationType: 'TotalCaption',
              customAggregate: () => {
                const gridData = this.$refs.salesStatusByPartGrid.getGridBatchData();
                const malePer = gridData
                  .map(item => item.malePer)
                  .reduce((acc, cur) => cur + acc, 0);
                const visitPer = gridData
                  .map(item => item.visitPer)
                  .reduce((acc, cur) => cur + acc, 0);
                return visitPer > 0
                  ? ((malePer / visitPer) * 100).toFixed(1)
                  : '-';
              },
            },
            {
              field: 'femalePer',
              aggregationType: 'TotalSum',
            },
            {
              field: 'femalePerRatio',
              aggregationType: 'TotalCaption',
              customAggregate: () => {
                const gridData = this.$refs.salesStatusByPartGrid.getGridBatchData();
                const femalePer = gridData
                  .map(item => item.femalePer)
                  .reduce((acc, cur) => cur + acc, 0);
                const visitPer = gridData
                  .map(item => item.visitPer)
                  .reduce((acc, cur) => cur + acc, 0);
                return visitPer > 0
                  ? ((femalePer / visitPer) * 100).toFixed(1)
                  : '-';
              },
            },
            {
              field: 'golfUnitPrice',
              aggregationType: 'TotalCaption',
              customAggregate: () => {
                const gridData = this.$refs.salesStatusByPartGrid.getGridBatchData();
                const feeAmt = gridData
                  .map(item => item.feeAmt)
                  .reduce((acc, cur) => cur + acc, 0);
                const cartAmt = gridData
                  .map(item => item.cartAmt)
                  .reduce((acc, cur) => cur + acc, 0);
                const rentAmt = gridData
                  .map(item => item.rentAmt)
                  .reduce((acc, cur) => cur + acc, 0);
                const visitPer = gridData
                  .map(item => item.visitPer)
                  .reduce((acc, cur) => cur + acc, 0);
                return visitPer > 0
                  ? Math.round((feeAmt + cartAmt + rentAmt) / visitPer)
                  : '-';
              },
            },
            {
              field: 'visitTeam',
              aggregationType: 'TotalSum',
            },
            {
              field: 'visitPer',
              aggregationType: 'TotalSum',
            },
            {
              field: 'totSalesAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'feeAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'cartAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'rentAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'foodAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'shopAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'roomAmt',
              aggregationType: 'TotalSum',
            },
          ],
        },
      ],
      dailySalesStatusByPartGridAggregates: [],
      salesStatusByPartList: [],
      pageSettings: {pageSize: 50},
      groupSettings: {
        columns: ["group"],
        showDropArea: false,
      },
    };
  },
  async created() {
    await this.initialize();
  },
  computed: {
    isDailyTab(){
      return this.selectedTab.startsWith('DAILY_');
    }
  },
  methods: {
    async initialize() {
      const nowMoment = await GolfERPService.fetchNow();
      this.now = nowMoment.toDate();
      const from = nowMoment.startOf('month').format(DATE_FORMAT_YYYY_MM_DD);
      const to = nowMoment.endOf('month').format(DATE_FORMAT_YYYY_MM_DD);
      this.dateRange = {
        from,
        to,
      };
      this.makeColumnsTemplate();
      await this.fetch();
    },
    async onViewSalesStatusByPart() {
      await this.fetch();
    },
    async selectTab(args) {
      this.selectedTab = this.tabList[args.selectedIndex].selectedTab;
      await this.fetch();
    },
    makeColumnsTemplate() {
      const fieldList = [
        'resveAbleTeam',
        'visitTeam',
        'visitPer',
        'nearbyPer',
        'nearbyPerRatio',
        'autoChkinPer',
        'autoChkinPerRatio',
        'malePer',
        'malePerRatio',
        'femalePer',
        'femalePerRatio',
        'golfUnitPrice',
        'totSalesAmt',
        'feeAmt',
        'cartAmt',
        'rentAmt',
        'foodAmt',
        'shopAmt',
        'roomAmt',
      ];
      const putAggregateTemplate = (field, comCode, isGolfUnitPrice = false) => {
        const fieldName = `${field}_${comCode}`;
        const fieldVisitPer = `visitPer_${comCode}`;
        const fieldFeeAmt = `feeAmt_${comCode}`;
        const fieldCartAmt = `cartAmt_${comCode}`;
        const fieldRentAmt = `rentAmt_${comCode}`;
        if (isGolfUnitPrice) {
          return {
            field: fieldName,
            aggregationType: 'TotalCaption',
            customAggregate: () => {
              const gridData = this.$refs.dailySalesStatusByPartGrid.getGridBatchData();
              const feeAmt = gridData
                .map(item => item[fieldFeeAmt])
                .reduce((acc, cur) => cur + acc, 0);
              const cartAmt = gridData
                .map(item => item[fieldCartAmt])
                .reduce((acc, cur) => cur + acc, 0);
              const rentAmt = gridData
                .map(item => item[fieldRentAmt])
                .reduce((acc, cur) => cur + acc, 0);
              const visitPer = gridData
                .map(item => item[fieldVisitPer])
                .reduce((acc, cur) => cur + acc, 0);
              return visitPer > 0
                ? Math.round((feeAmt + cartAmt + rentAmt) / visitPer)
                : '-';
            },
          };
        } else {
          const ratioList = [
            'nearbyPer',
            'autoChkinPer',
            'malePer',
            'femalePer',
          ]
            ?.map(item => `${item}Ratio`);
          if (ratioList.includes(field)) {
            return {
              field: fieldName,
              aggregationType: 'TotalCaption',
              customAggregate: () => {
                const gridData = this.$refs.dailySalesStatusByPartGrid.getGridBatchData();
                const fieldTargetPer = `${field}_${comCode}`
                  ?.replace('Ratio', '');
                const targetPer = gridData
                  .map(item => item[fieldTargetPer])
                  .reduce((acc, cur) => cur + acc, 0);
                const visitPer = gridData
                  .map(item => item[fieldVisitPer])
                  .reduce((acc, cur) => cur + acc, 0);
                return visitPer > 0 && targetPer > 0
                  ? ((targetPer / visitPer) * 100).toFixed(1)
                  : '-';
              },
            };
          } else {
            return {
              field: fieldName,
              aggregationType: 'TotalSum',
            };
          }
        }
      };
      const putColumnTemplate = (headerText, fieldSuffix) => {
        return {
          field: `field${fieldSuffix}`,
          headerText,
          columns: [
            {
              field: `teamInfo${fieldSuffix}`,
              headerText: '팀정보',
              columns: [
                {
                  field: `resveAbleTeam${fieldSuffix}`,
                  headerText: '가용팀수',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 90,
                  type: 'number',
                },
                {
                  field: `visitTeam${fieldSuffix}`,
                  headerText: '운영팀수',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `visitPer${fieldSuffix}`,
                  headerText: '내장객수',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
              ],
            },
            {
              headerText: '지역민',
              columns: [
                {
                  field: `nearbyPer${fieldSuffix}`,
                  headerText: '내장객',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `nearbyPerRatio${fieldSuffix}`,
                  headerText: '비율(%)',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
              ],
            },
            {
              headerText: '키오스크',
              columns: [
                {
                  field: `autoChkinPer${fieldSuffix}`,
                  headerText: '내장객',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `autoChkinPerRatio${fieldSuffix}`,
                  headerText: '비율(%)',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
              ],
            },
            {
              headerText: '남자',
              columns: [
                {
                  field: `malePer${fieldSuffix}`,
                  headerText: '내장객',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `malePerRatio${fieldSuffix}`,
                  headerText: '비율(%)',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
              ],
            },
            {
              headerText: '여자',
              columns: [
                {
                  field: `femalePer${fieldSuffix}`,
                  headerText: '내장객',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `femalePerRatio${fieldSuffix}`,
                  headerText: '비율(%)',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
              ],
            },
            {
              field: `golfUnitPrice${fieldSuffix}`,
              headerText: '골프객단가',
              textAlign: 'right',
              isNumericType: true,
              width: 110,
              type: 'number',
            },
            {
              headerText: '매출정보',
              columns: [
                {
                  field: `totSalesAmt${fieldSuffix}`,
                  headerText: '합계',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `feeAmt${fieldSuffix}`,
                  headerText: '입장료',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `cartAmt${fieldSuffix}`,
                  headerText: '카트료',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `rentAmt${fieldSuffix}`,
                  headerText: '대여료',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `foodAmt${fieldSuffix}`,
                  headerText: '식음료',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `shopAmt${fieldSuffix}`,
                  headerText: '프로샵',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
                {
                  field: `roomAmt${fieldSuffix}`,
                  headerText: '객실매출',
                  textAlign: 'right',
                  isNumericType: true,
                  width: 110,
                  type: 'number',
                },
              ],
            },
          ],
        };
      };
      let aggregates = [];
      if (this.partDivList.length > 0) {
        fieldList
          ?.forEach(field => aggregates.push(
            putAggregateTemplate(field, 'SUM', field === 'golfUnitPrice'),
          ));
        this.dailySalesStatusByPartGridColumn.push(
          putColumnTemplate('합계', '_SUM'),
        );
        this.partDivList
          .forEach(item => {
            fieldList
              ?.forEach(field => aggregates.push(
                putAggregateTemplate(field, item.comCode, field === 'golfUnitPrice'),
              ));
            this.dailySalesStatusByPartGridColumn.push(
              putColumnTemplate(item.comName, `_${item.comCode}`),
            );
          });
      }
      this.dailySalesStatusByPartGridAggregates = [{columns: aggregates}];
    },
    salesStatusByPartGridQueryCellInfo(args) {
      const {
        cell,
        column: {field, type},
        data,
      } = args;
      if (field === 'bsnCode') {
        if(data.blank) {
          cell.innerText = "";
        } else {
          cell.style.borderTop = "none";
        }
        if(data.border) {
          cell.style.borderTop = "none";
        }
      }
      if (field === 'totSalesAmt') {
        cell.style.fontWeight = 'bold';
      }
      if (type.toLowerCase() === "number" && !data[field]) {
        cell.innerText = "-";
      }
    },
    dailySalesStatusByPartGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if (
        [
          'field_SUM',
          'teamInfo_SUM',
          'resveAbleTeam_SUM',
        ]
          .concat(this.partDivList.map(item => `field_${item.comCode}`))
          .concat(this.partDivList.map(item => `teamInfo_${item.comCode}`))
          .concat(this.partDivList.map(item => `resveAbleTeam_${item.comCode}`))
          .includes(field)
      ) {
        node.classList.add(this.$t('className.grid.devVerticalLine'));
      }
    },
    dailySalesStatusByPartGridQueryCellInfo(args) {
      const {
        cell,
        column: {field, type},
        data,
      } = args;
      if (field === 'dwCode') {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      }
      if (field === 'bsnCode') {
        cell.style.color = commonCodesGetColorValue('BSN_CODE', data.bsnCode);
      }
      if (
        ['resveAbleTeam_SUM']
          .concat(this.partDivList.map(item => `resveAbleTeam_${item.comCode}`))
          .includes(field)
      ) {
        cell.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (typeof data[field] === "number" && data[field] < 1) {
        cell.innerText = '-';
      }
      if (data.isMonthDivLine) {
        cell.style.borderTop = `1px solid #000`;
      }
      if (type?.toLowerCase() === "number" && !data[field]) {
        cell.innerText = "-";
      }
    },
    excel() {
      switch (this.selectedTab) {
        case 'NORMAL':
          this.$refs.salesStatusByPartGrid.excelExport();
          break;
        case 'DAILY_SUM':
        case 'DAILY_1':
        case 'DAILY_2':
        case 'DAILY_3':
          this.$refs.dailySalesStatusByPartGrid.excelExport();
          break;
      }
    },

    // API
    async fetch() {
      this.salesStatusByPartList = [];
      let salesStatusByPartList = (await GolfErpAPI.fetchSalesStatusByPartDiv({
        bsnDateFrom: this.dateRange.from,
        bsnDateTo: this.dateRange.to,
        salesSumDiv: this.searchConditions.salesSumDiv,
        aditFlag: this.aditFlag,
        bsnCode: this.selectedTab === 'NORMAL' ? "" : this.weekRadio
      })) || [];

      const fieldList = [
        'resveAbleTeam',
        'visitTeam',
        'visitPer',
        'nearbyPer',
        'nearbyPerRatio',
        'autoChkinPer',
        'autoChkinPerRatio',
        'malePer',
        'malePerRatio',
        'femalePer',
        'femalePerRatio',
        'golfUnitPrice',
        'totSalesAmt',
        'feeAmt',
        'cartAmt',
        'rentAmt',
        'foodAmt',
        'shopAmt',
        'roomAmt'
      ];
      salesStatusByPartList = salesStatusByPartList.map((data)=>{
        if(!this.holeTeamApplyCheck) {
          return data;
        } else {
          return {
            ...data,
            resveAbleTeam:data.resveAbleTeamHoleDiv,
            visitPer:data.visitPerHoleDiv,
            visitTeam:data.visitTeamHoleDiv,
          };
        }
      });
      switch (this.selectedTab) {
        case 'NORMAL':
          this.fetchForNormal(salesStatusByPartList, fieldList);
          break;
        case 'DAILY_SUM':
        case 'DAILY_1':
        case 'DAILY_2':
        case 'DAILY_3':
          await this.fetchForDaily(salesStatusByPartList, fieldList);
          await this.showHideColumnForDaily();
          break;
      }
    },
    showHideColumnForDaily(){
      //일별 파트별 시트일경우 탭에 맞게 컬럼을 숨김처리(공통항목 처리안함,_없는항목)
      const columns = this.$refs.dailySalesStatusByPartGrid.getColumns();
      const subFix = this.selectedTab.replace("DAILY_","");
      for(const item of columns){
        if(item.field && item.field.indexOf("_") > 0){
          if(item.field.endsWith(`_${subFix}`)){
            item.visible = true;
          }else{
            item.visible = false;
          }
        }
      }
      this.$refs.dailySalesStatusByPartGrid.refreshColumns();
    },
    fetchForNormal(salesStatusByPartList, fieldList) {
      let result = [];
      const day = [];
      const end = [];
      // let blank = null;
      // salesStatusByPartList =
      salesStatusByPartList.map((data)=>{
        if(data.bsnCode === "WEEKEND")  {
          end.push(data);
        } else if(data.bsnCode === "WEEKDAY"){
          day.push(data);
        }
      });
      salesStatusByPartList = [day,end];
      salesStatusByPartList.forEach((data,idx)=>{
            this.partDivList
                ?.forEach((partDiv,partIdx) => {
                  let block = {};
                  block.partDivKey = partDiv.comCode;
                  block.partDiv = partDiv.comCode;
                  fieldList
                      ?.filter(field => (field !== 'golfUnitPrice'))
                      ?.forEach(field =>
                          block[field] = data
                              ?.filter(item => item.partDiv === partDiv.comCode)
                              ?.reduce((acc, cur) => acc + cur[field], 0),
                      );
                  // 지역민 비율

                  //구분선을 없애기 위해 이렇게 함 갯수는 3개 고정이라 하드코딩
                  block.blank = !(partIdx === 1);
                  block.border = (partIdx === 2);
                  // block.blank = blank == idx;
                  // blank = idx;
                  block.bsnCode = idx === 0 ? "WEEKDAY" : "WEEKEND";
                  block.group  = idx === 0 ? "WEEKDAY" : "WEEKEND";
                  // 지역민 비율
                  block.nearbyPerRatio = block.visitPer > 0
                    ? ((block.nearbyPer / block.visitPer) * 100).toFixed(1)
                    : 0;
                  // 키오스크 비율
                  block.autoChkinPerRatio = block.visitPer > 0
                    ? ((block.autoChkinPer / block.visitPer) * 100).toFixed(1)
                    : 0;
                  // 남자 비율
                  block.malePerRatio = block.visitPer > 0
                    ? ((block.malePer / block.visitPer) * 100).toFixed(1)
                    : 0;
                  // 여자 비율
                  block.femalePerRatio = block.visitPer > 0
                    ? ((block.femalePer / block.visitPer) * 100).toFixed(1)
                    : 0;
                  // 골프객단가
                  block.golfUnitPrice = block.visitPer > 0
                    ? Math.round((block.feeAmt + block.cartAmt + block.rentAmt) / block.visitPer)
                    : 0;
                  result.push(block);
                  block = {};
                });
      });
      this.salesStatusByPartList = result;
    },

    fetchForDaily(salesStatusByPartList, fieldList) {
      let result = [];
      let block = {};
      let tempSavedBsnDateKey = null;
      salesStatusByPartList
        ?.forEach((item, index) => {
          const blocking = () => {
            if (tempSavedBsnDateKey) {
              fieldList
                ?.forEach(field => {
                  const key = `${field}_SUM`;
                  block[key] = 0;
                });
              result.push(block);
              block = {};
            }
            tempSavedBsnDateKey = item.bsnDate;
          };
          if (tempSavedBsnDateKey !== item.bsnDate) {
            blocking();
          }
          block.bsnDateKey = item.bsnDate;
          block.bsnDate = moment(item.bsnDate).format(DATE_FORMAT_YYYY_MM_DD);
          block.bsnCode = item.bsnCode;
          block.dwCode = item.dwCode;
          if (item.partDiv) {
            const key = `_${item.partDiv}`;
            fieldList
              ?.forEach(field => block[`${field}${key}`] = item[field]);
          } else {
            this.partDivList
              ?.forEach(partDiv => {
                const key = `_${partDiv.comCode}`;
                fieldList
                  ?.forEach(field => block[`${field}${key}`] = item[field]);
              });
          }
          if (index === salesStatusByPartList.length - 1) {
            blocking();
          }
        });
      let tempSavedMonth = null;
      this.salesStatusByPartList = result
        ?.map(item => {
          fieldList
            ?.filter(field => field !== 'golfUnitPrice')
            ?.forEach(field => {
              const key = `${field}_SUM`;
              this.partDivList
                ?.forEach(partDiv => item[key] += (item[`${field}_${partDiv.comCode}`] || 0));
            });
          // 지역민 비율
          item.nearbyPerRatio_SUM = item.visitPer_SUM > 0 && item.nearbyPer_SUM > 0
            ? ((item.nearbyPer_SUM / item.visitPer_SUM) * 100).toFixed(1)
            : 0;
          // 키오스크 비율
          item.autoChkinPerRatio_SUM = item.visitPer_SUM > 0 && item.autoChkinPer_SUM > 0
            ? ((item.autoChkinPer_SUM / item.visitPer_SUM) * 100).toFixed(1)
            : 0;
          // 남자 비율
          item.malePerRatio_SUM = item.visitPer_SUM > 0 && item.malePer_SUM > 0
            ? ((item.malePer_SUM / item.visitPer_SUM) * 100).toFixed(1)
            : 0;
          // 여자 비율
          item.femalePerRatio_SUM = item.visitPer_SUM > 0 && item.femalePer_SUM > 0
            ? ((item.femalePer_SUM / item.visitPer_SUM) * 100).toFixed(1)
            : 0;
          // 골프객단가
          item.golfUnitPrice_SUM = item.visitPer_SUM > 0
            ? Math.round((item.feeAmt_SUM + item.cartAmt_SUM + item.rentAmt_SUM) / item.visitPer_SUM)
            : 0;
          const month = moment(item.bsnDate).format(DATE_FORMAT_M);
          if (tempSavedMonth !== month) {
            if (tempSavedMonth) {
              item.isMonthDivLine = true;
            }
            tempSavedMonth = month;
          }
          return item;
        });
    },
  },
};
</script>
